/**
 * 是否是移动端
 * @returns
 */
export function hasMobileDevice() {
  var uA = navigator.userAgent.toLowerCase();
  var ipad = uA.match(/ipad/i) == "ipad";
  var iphone = uA.match(/iphone os/i) == "iphone os";
  var midp = uA.match(/midp/i) == "midp";
  var uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var uc = uA.match(/ucweb/i) == "ucweb";
  var android = uA.match(/android/i) == "android";
  var windowsce = uA.match(/windows ce/i) == "windows ce";
  var windowsmd = uA.match(/windows mobile/i) == "windows mobile";
  if (
    !(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)
  ) {
    // PC 端
    return false;
  } else {
    // 移动端
    return true;
  }
}

/**
 * 新窗口打卡
 * @param {*} href
 */
export function tapOpen(href, target = "_self") {
  let link_el = document.createElement("a");
  link_el.style.display = "none";
  link_el.target = target;
  link_el.href = href;
  document.body.appendChild(link_el);
  link_el.click();
  document.body.removeChild(link_el);
}

import http from "./axios.js";

export function fetchBanner(config) {
  return http({
    url: "/common/company-banner/list",
    method: "post",
    ...config,
  });
}

export function fetchActivityList(config) {
  return http({
    url: "/activity/brand-activity/index",
    method: "post",
    ...config,
  });
}

export function fetchMapData(config) {
  return http({
    url: "/common/company-banner/map",
    method: "post",
    ...config,
  });
}

export function fetchActivityDetail(config) {
  return http({
    url: "/activity/brand-activity/detail",
    method: "post",
    ...config,
  });
}

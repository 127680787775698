import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/h5",
    name: "home-h5",
    component: () => import("../views/home-h5.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/layout",
    name: "layout",
    component: () => import("../views/layout.vue"),
    redirect: "/layout/brand-activity",
    children: [
      {
        path: "brand-activity",
        name: "BrandActivity",
        component: () =>
          import(
            /* webpackChunkName: "brand-activity" */ "../views/BrandActivity.vue"
          ),
      },
      {
        path: "activity-detail",
        name: "acitivity-detail",
        component: () => import("../views/ActivityDetail.vue"),
      },
    ],
  },
  //
  {
    path: "/layout-h5",
    name: "layout-h5",
    component: () => import("../views/layout-h5.vue"),
    redirect: "/layout-h5/brand-activity-h5",
    children: [
      {
        path: "brand-activity-h5",
        name: "BrandActivity-h5",
        component: () =>
          import(
            /* webpackChunkName: "brand-activity" */ "../views/brand-activity-h5.vue"
          ),
      },
      {
        path: "activity-detail-h5",
        name: "acitivity-detail-h5",
        component: () => import("../views/activity-detail-h5.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

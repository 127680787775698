<!--  -->
<template>
  <div class="page-footer">
    <div class="row">
      <h2 class="title">北京一博千禧科技有限公司</h2>

      <div class="phone-box">
        <img src="@/assets/image/phone_icon.png" alt="" class="icon" />
        <p class="phone">
          加盟热线：<span class="phone-text">400-969-6261</span>
        </p>
      </div>
    </div>
    <div class="row">
      <p class="item">北京一博千禧科技有限公司</p>
      <p class="item">电信信息业务许可证：京B2-20180237</p>
    </div>
    <div class="row">
      <p class="">广播电视节目制作经营许可证：</p>
      <p class="">（京）字第16582号opyright@2019-2029</p>
    </div>
    <div class="row">
      <p class="item flex">
        北京一博千禧科技有限公司 All Rights Reserved
        <span>京ICP备16011149号-4</span>
      </p>
      <p class="item-right">
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802027627"
        >
          <img src="@/assets/image/ba.png" alt="" class="icon" />
          京公网安备 11010802037573号
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-footer",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.page-footer {
  background-color: #101b33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  padding: 30px 0;
  font-size: 14px;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(2) {
      font-size: 14px;
    }
    &:nth-child(3) {
      font-size: 14px;
    }
    p {
      margin: 0 0;
      line-height: 1.6;
    }
    .item-right {
      margin-left: 10px;
      display: flex;
      align-items: center;
      .icon {
        margin: 0 4px 0 0;
        width: 15px;
        height: 15px;
      }
      a {
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;
      }
    }
    .title {
      font-size: 32px;
    }
    .phone-box {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .icon {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 0;
      }
      .phone-text {
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .page-footer {
    background-color: #101b33;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column;
    padding: 30px 0;
    font-size: 14px;

    .row {
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:nth-child(2) {
        font-size: 14px;
      }
      &:nth-child(3) {
        font-size: 14px;
      }
      .flex {
        display: flex;
        flex-direction: column;
      }
      .title {
        font-size: 24px;
      }
      .item-right {
        margin-left: 10px;
        display: flex;
        align-items: center;
        .icon {
          margin: 0 4px 0 0;
        }
      }
      p {
        line-height: 1.4;
        margin: 5px 0;
        text-align: center;
      }
      .phone-box {
        display: flex;
        align-items: center;
        margin-left: 40px;
        .icon {
          width: 18px;
          height: 18px;
          margin: 0 5px 0 0;
        }
      }
    }
  }
}
</style>

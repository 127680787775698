import axios from "axios";

const service = axios.create({
  baseURL: "https://www.yulin365.net/api/v1",
  timeout: 10 * 1000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default service;
